<script>
export default {
  name: "HXContentCard",
  props: {
    defaultToBack: {
      type: Boolean,
      default: false,
      required: false,
    },
    toBack: {
      type: Object,
      default: () => ({
        name: "",
        params: {},
      }),
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex align-items-center">
              <div
                v-if="(toBack && toBack.name) || defaultToBack"
                v-b-tooltip.hover
                title="Regresar"
                class="arrow-nav mr-2"
                @click="
                  defaultToBack
                    ? $router.back()
                    : toBack.name
                    ? $router.push(toBack)
                    : null
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0"
                  />
                </svg>
              </div>
              <h3 class="card-title">
                <slot name="title">
                  <b>{{ title }}</b>
                </slot>
              </h3>
            </div>
          </template>

          <!-- aqui va el contenido -->
          <slot />
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
