import "@babel/polyfill";
import "mutationobserver-shim";
import Raphael from "raphael/raphael";
global.Raphael = Raphael;
import Vue from "vue";
import "./plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./directives";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import IdleVue from "idle-vue";

import VueToggles from "vue-toggles";

import VueSocketIO from "vue-socket.io";

import "css-peeps/css-peeps.css";
// import SocketIO from "socket.io-client";

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'https://helex.mecasite.com:5105',
//   vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   },
//   // options: { path: "/my-app/" } //Optional options
// }))
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import HelexSelect from "@/components/global/HelexSelect";
Vue.component("HelexSelect", HelexSelect);
import HelexCheckBtn from "@/components/global/HelexCheckBtn";

import HXContentCard from "@/components/helexium/HXContentCard.vue";

Vue.component("HelexCheckBtn", HelexCheckBtn);

Vue.component("HXContentCard", HXContentCard);

const options = { transports: ["polling"] };

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: "https://helex.mecasite.com",
    options: options,
  })
);

Vue.component("VueToggles", VueToggles);

Vue.config.productionTip = false;
import loading from "vuejs-loading-screen";

// Vue.use(loading, {
//   bg: "#3a5c91ad",
//   slot: `
//     <div class="px-5 py-3 rounded glass-effect">
//       <h3 class="text-3xl text-white"><i class="fa fa-spinner fa-spin"></i> ${"Espere..."} </h3>
//       <div class="dots"></div>
//     </div>
//   `,
// });

Vue.use(loading, {
  bg: "#3a5c91ad",
  slot: `
    <div class="glass-effect">
      <div class="loader --5"></div>
      <h3 class="text-3xl text-white">Cargando...</h3>
    </div>
  `,
});

import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(VueMoment, {
  moment,
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1500000, // -- 15 minutos - para desarrollo
  // idleTime: 500000 // 5 minutos - dejar este por defecto
});

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

new Vue({
  onIdle() {
    console.log("zzzzzz");
    this.messageStr = "ZZZ";
    this.$router.replace("/");
    // localStorage.removeItem('setPersonaAct')
  },
  onActive() {
    this.messageStr = "Hello";
    console.log("Hola!");
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export var eventBus = new Vue();

import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  colors: {
    // primary: "#186bdd",
    // primary: "#186bdd", claro odoo
    primary: "#1A5CFF",
    success: "#17a76c",
    // success: "rgb(0, 123, 146)",
    danger: "rgb(242, 19, 67)",
    warning: "rgb(255, 130, 0)",
    dark: "#131416",
    transparent: "transparent",
  },
});
