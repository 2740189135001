import data from "./inspeccionSchema";

export default {
  state: {
    documentacion: null,
    OrdenYAseo: null,
    carroceria: null,
    cabina: null,
    motor: null,
    herramientas: null,
    kitAmbiental: null,
  },
  mutations: {
    initInspeccionVehiculo(state) {
      state.documentacion = data.documentacion;
      state.OrdenYAseo = data.OrdenYAseo;
      state.carroceria = data.carroceria;
      state.cabina = data.cabina;
      state.motor = data.motor;
      state.herramientas = data.herramientas;
      state.kitAmbiental = data.kitAmbiental;
    },
  },
  actions: {
    initInspeccionVehiculo({ commit }) {
      commit("initInspeccionVehiculo");
    },
    async getVehiculosInspeccion({ rootGetters }, sucursalId) {
      const query = `
        WITH UltimaInspeccion AS (
            SELECT 
                rpv.VehiculoId,
                rpv.FechaRegistro,
                rpv.FechaFinalizacion,
                ROW_NUMBER() OVER (PARTITION BY rpv.VehiculoId ORDER BY rpv.FechaRegistro DESC) AS rn
            FROM 
                RevisionPeriodicaVehiculo rpv
        )

        SELECT 
            v.Placa,
            v.Id AS VehiculoId,
            s.Descripcion AS Sucursal, 
            ISNULL(COUNT(rpv.Id), 0) AS TotalInspecciones, 
            MAX(rpv.FechaRegistro) AS FechaInicioUltimaInspeccion, 
            CASE
                WHEN COUNT(rpv.Id) = 0 THEN NULL 
                WHEN u.FechaFinalizacion IS NOT NULL THEN u.FechaFinalizacion
                ELSE NULL
            END AS FechaFinalUltimaInspeccion,
            CASE
                WHEN COUNT(rpv.Id) = 0 THEN 'Sin Inspecciones'
                WHEN u.FechaFinalizacion IS NOT NULL THEN 'Finalizada'
                ELSE 'En Proceso'
            END AS EstadoUltimaInspeccion
        FROM 
            Vehiculo v
        JOIN
            Sucursal s ON v.SucursalId = s.Id
        LEFT JOIN
            UltimaInspeccion u ON v.Id = u.VehiculoId AND u.rn = 1
        LEFT JOIN
            RevisionPeriodicaVehiculo rpv ON v.Id = rpv.VehiculoId

        ${sucursalId ? `WHERE s.Id = @sucursalId` : ""}
        
        GROUP BY 
            v.Placa, v.Id, s.Descripcion, u.FechaFinalizacion, u.FechaRegistro
        ORDER BY 
            MAX(rpv.FechaRegistro) DESC; 

      `;
      try {
        const response = await rootGetters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              sucursalId,
            },
          },
        });
        if (response.ok) {
          const allData = await response.json();
          console.log(allData.data);

          return allData.data;
        } else {
          console.error("Error en la respuesta del servidor", response);
          return [];
        }
      } catch (error) {
        console.error("Error en getSucursales:", error);
        return [];
      }
    },

    async getSucursalPersona({ rootGetters }, personaId) {
      const query = `
        SELECT pu.PersonaId, pu.SucursalId
        FROM PersonaUbicacion pu 
        JOIN Persona p ON pu.PersonaId = p.Id
        WHERE p.Id = @personaId
      `;
      try {
        const response = await rootGetters.fetchPost({
          path: `DynamicQuery/`,
          data: {
            query,
            parameters: {
              personaId,
            },
          },
        });
        if (response.ok) {
          const allData = await response.json();
          console.log(allData.data);

          return allData.data;
        } else {
          console.error("Error en la respuesta del servidor", response);
          return [];
        }
      } catch (error) {
        console.error("Error en getSucursales:", error);
        return [];
      }
    },
    async getListInspeccionesByVehiculoId({ rootGetters }, vehiculoId) {
      const query = `
        SELECT 
            rpv.Id as IdInspeccion,
            rpv.VehiculoId,
            MAX(rpv.FechaRegistro) AS FechaInicio, 
            CASE 
                WHEN rpv.FechaFinalizacion IS NOT NULL THEN rpv.FechaFinalizacion
                ELSE NULL
            END AS FechaFinal,
            CASE 
                WHEN rpv.FechaFinalizacion IS NOT NULL THEN 'Finalizada'
                ELSE 'En Proceso'
            END AS Estado
        FROM 
            Vehiculo v
        JOIN 
            RevisionPeriodicaVehiculo rpv ON v.Id = rpv.VehiculoId
        WHERE
            rpv.VehiculoId = @vehiculoId
        GROUP BY 
            rpv.Id , rpv.VehiculoId, rpv.FechaFinalizacion, rpv.FechaRegistro
        ORDER BY 
            MAX(rpv.FechaRegistro) DESC; 
      `;
      try {
        const response = await rootGetters.fetchPost({
          path: `DynamicQuery/`,
          data: { query, parameters: { vehiculoId } },
        });
        if (response.ok) {
          const allData = await response.json();
          console.log(allData.data);

          return allData.data;
        } else {
          console.error("Error en la respuesta del servidor", response);
          return [];
        }
      } catch (error) {
        console.error("Error en getSucursales:", error);
        return [];
      }
    },
  },
  getters: {},
};
