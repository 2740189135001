import Vue from "vue";
import VueRouter from "vue-router";
import publicViews from "./publicViews";
import userLoggedIn from "./middleware/userLoggedIn";
import { jwtDecode } from "jwt-decode";


const SignIn = () => import("@/views/backend/Auth/SignIn");
const HojaDeVidaVehiculo = () =>
  import("@/views/Helexium/HojaDeVidaVehiculo/HojaDeVidaVehiculo");
const IndicadoresPESV = () =>
  import("@/views/Helexium/IndicadoresPESV/IndicadoresPESV");
const InspeccionVehiculo = () =>
  import("@/views/Helexium/InspeccionVehiculo/InspeccionVehiculo");
const VisualizarInspecciones = () =>
  import("@/views/Helexium/InspeccionVehiculo/VisualizarInspecciones");
const ContentFormInspeccion = () =>
  import("@/views/Helexium/InspeccionVehiculo/ContentFormInspeccion");
const RegistroDeVehiculos = () =>
  import("@/views/Helexium/RegistroDeVehiculos/RegistroDeVehiculos");

const SGSST = () => import("@/views/Helexium/SGSST/SGSST");
const SGSSTProfesiograma = () => import("@/views/Helexium/SGSST/Profesiograma");
// const SGSSTInspeccionElementos = () =>
//   import("@/views/Helexium/SGSST/InspeccionElementos");
// const SGSSTListarInspeccionElementos = () =>
//   import("@/views/Helexium/SGSST/ListarInspeccionElementos");
const SGSSTListarProfesiograma = () =>
  import("@/views/Helexium/SGSST/ListarProfesiograma");
const SGSSTInspeccionElementos = () =>
  import("@/views/Helexium/SGSST/InspeccionElementos");
const SGSSTListarInspeccionElementos = () =>
  import("@/views/Helexium/SGSST/ListarInspeccionElementos");

const PESV = () => import("@/views/Helexium/PESV/PESV");

const PeligrosYRiesgos = () =>
  import("@/views/Helexium/PeligrosYRiesgos/PeligrosYRiesgos");
const SGSSTFichaIndicadores = () =>
  import("@/views/Helexium/SGSSTFichaIndicadores/SGSSTFichaIndicadores");
const PESVFichaIndicadores = () =>
  import("@/views/Helexium/PESVFichaIndicadores/PESVFichaIndicadores");

const Pendientes = () => import("@/views/Helexium/Evaluaciones/Pendientes");
const Finalizadas = () => import("@/views/Helexium/Evaluaciones/finalizadas");

const CaracterizacionEnfermedadLaboral = () =>
  import(
    "@/views/Helexium/CaracterizacionEnfermedadLaboral/CaracterizacionEnfermedadLaboral"
  );

const Empresa = () => import("@/views/Helexium/Empresas/Empresa");
const Empresas_agregar = () =>
  import("@/views/Helexium/Empresas/Empresas_agregar");
const Empresas_editar = () =>
  import("@/views/Helexium/Empresas/Empresas_editar");
const Persona = () => import("@/views/Helexium/Persona/Persona");
const Persona_agregar = () =>
  import("@/views/Helexium/Persona/Persona_agregar");
const Persona_historial_laboral = () =>
  import("@/views/Helexium/Persona/Persona_historial_laboral");
const Persona_editar = () => import("@/views/Helexium/Persona/Persona_editar");
const Sucursales = () => import("@/views/Helexium/Sucursales/Sucursales");
const Sucursales_agregar = () =>
  import("@/views/Helexium/Sucursales/Sucursales_agregar");
const Sucursales_editar = () =>
  import("@/views/Helexium/Sucursales/Sucursales_editar");

const Departamentos = () =>
  import("@/views/Helexium/Departamentos/Departamentos");
const Departamentos_agregar = () =>
  import("@/views/Helexium/Departamentos/Departamentos_agregar");
const Departamentos_editar = () =>
  import("@/views/Helexium/Departamentos/Departamentos_editar");

const Areas = () => import("@/views/Helexium/Areas/Areas");
const Areas_agregar = () => import("@/views/Helexium/Areas/Areas_agregar");
const Areas_editar = () => import("@/views/Helexium/Areas/Areas_editar");
const Competencias = () => import("@/views/Helexium/Competencias/Competencias");
const Competencias_agregar = () =>
  import("@/views/Helexium/Competencias/Competencias_agregar");
const Competencias_editar = () =>
  import("@/views/Helexium/Competencias/Competencias_editar");
const RegistroDeAccidentes = () =>
  import("@/views/Helexium/RegistroDeAccidentes/RegistroDeAccidentes");

const Enviar_medallas = () =>
  import("@/views/Helexium/PerfilMedallas/Enviar_medallas");

const dashboard_nuevo = () =>
  import("@/views/Helexium/dashboard_nuevo/dashboard_nuevo");

const RegistroDeIncapacidades = () =>
  import("@/views/Helexium/RegistroDeIncapacidades/RegistroDeIncapacidades");

// preguntas
const Preguntas = () => import("@/views/Helexium/Preguntas/Preguntas");
const Preguntas_agregar = () =>
  import("@/views/Helexium/Preguntas/Preguntas_agregar");
const Preguntas_lista = () =>
  import("@/views/Helexium/Preguntas/Preguntas_lista");

// cargos
const Cargos = () => import("@/views/Helexium/Cargos/Cargos");
const Cargos_agregar = () => import("@/views/Helexium/Cargos/Cargos_agregar");
const Cargos_editar = () => import("@/views/Helexium/Cargos/Cargos_editar");
const Cargos_perfil = () => import("@/views/Helexium/Cargos/Cargos_perfil");

const hoja_vida = () => import("@/views/Helexium/Mi_perfil/hoja_vida");

// medallas
const Medallas = () => import("@/views/Helexium/Medallas/Medallas");
const Medallas_agregar = () =>
  import("@/views/Helexium/Medallas/Medallas_agregar");
const Medallas_editar = () =>
  import("@/views/Helexium/Medallas/Medallas_editar");

// reportes
const Reportes = () => import("@/views/Helexium/Reportes/Reportes");
const Reportes_agregar = () =>
  import("@/views/Helexium/Reportes/Reportes_agregar");
const Reportes_editar = () =>
  import("@/views/Helexium/Reportes/Reportes_editar");

// perfil cargo
const PerfilCargo = () => import("@/views/Helexium/PerfilCargo/PerfilCargo");
const PerfilMedallas = () =>
  import("@/views/Helexium/PerfilMedallas/PerfilMedallas");
const PerfilComentarios = () =>
  import("@/views/Helexium/PerfilComentarios/PerfilComentarios");

const Tipo_Competencias = () =>
  import("@/views/Helexium/tipos/Tipo_Competencias");
const Tipo_Identidad = () => import("@/views/Helexium/tipos/Tipo_Identidad");
const Tipo_Niveles_cargo = () =>
  import("@/views/Helexium/tipos/Tipo_Niveles_cargo");

const Ciclo_evaluaciones = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones");
const Ciclo_evaluaciones_editar = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones_editar");
const Ciclo_evaluaciones_agregar = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones_agregar");
const evaluacion = () => import("@/views/Helexium/Evaluaciones/evaluaciones");

//academia y cursos
const AcademiaVideos = () =>
  import("@/views/Helexium/Ayuda/Academia/AcademiaVideos");
const Cursos = () => import("@/views/Helexium/Ayuda/Academia/Cursos");
const InfoCurso = () =>
  import("@/views/Helexium/Ayuda/Academia/InfoCurso/InfoCurso");
const SoporteVideos = () =>
  import("@/views/Helexium/Ayuda/Soporte/SoporteVideos");
const VideoManager = () =>
  import("@/views/Helexium/Ayuda/AdministradorDeVideo/VideoManager");

const AdministradorDeVacantes = () =>
  import("@/views/Helexium/Vacantes/AdministradorDeVacantes");
const NuevaVacante = () => import("@/views/Helexium/Vacantes/NuevaVacante");
const EditarVacante = () => import("@/views/Helexium/Vacantes/EditarVacante");

const ListaDePostulantes = () =>
  import("@/views/Helexium/Postulantes/ListaDePostulantes");

const ProcesoDePreseleccion = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/ProcesoDePreseleccion");
const NuevaPostulacion = () =>
  import("@/views/Helexium/Postulantes/NuevaPostulacion");
const RegistroDePostulacion = () =>
  import("@/views/Helexium/Postulantes/RegistroDePostulacion");

const ProcesoDeSeleccion = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/ProcesoDeSeleccion");

const EvaluarDocumentosDeSeleccion = () =>
  import(
    "@/views/Helexium/ReclutamientoYSeleccion/EvaluarDocumentosDeSeleccion"
  );
const VinculacionDePostulantes = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/VinculacionDePostulantes");

//Data lake
const DataLake = () => import("@/views/Helexium/DataLake/dataLake");
const ReadXlsFile = () => import("@/views/Helexium/SuperAdmin/ReadXlsFile");
const UploadHonorarios = () =>
  import("@/views/Helexium/SuperAdmin/UploadHonorarios");

//NOMINA

const Prestaciones = () =>
  import("@/views/Helexium/Nomina/Prestaciones/Prestaciones");
const MisHonorarios = () =>
  import("@/views/Helexium/Nomina/Prestaciones/MisHonorarios");
const Porcentajes = () =>
  import("@/views/Helexium/Nomina/Prestaciones/Porcentajes");
const Subvencion = () =>
  import("@/views/Helexium/Nomina/Prestaciones/Subvencion");

// const Honorarios = () =>
//   import("@/views/Helexium/Nomina/Honorarios/Honorarios");

const Numeraciones = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeraciones");
const Numeracion_agregar = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeracion_agregar");
const Numeracion_editar = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeracion_editar");

const Devengados = () => import("@/views/Helexium/Nomina/Devengado/Devengados");
const Deducciones = () =>
  import("@/views/Helexium/Nomina/Deduccion/Deducciones");
const Nominas = () => import("@/views/Helexium/Nomina/Nomina/Nomina");
const Nomina_Editar = () =>
  import("@/views/Helexium/Nomina/Nomina/Nomina_Editar");
const Liquidacion = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion");
const Liquidacion_Agregar = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Agregar");
const Liquidacion_Editar = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Editar");
const LiquidacionEditarPrimaServicios = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Editarv2");
const liquidacioneditardetalle = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_EditarDetalle");
const liquidacioneditardetallev2 = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_EditarDetalleV2");

const RegistroNovedades = () =>
  import("@/views/Helexium/Nomina/RegistroNovedades/RegistroNovedades");

const AutorizacionFunciones = () =>
  import("@/views/Helexium/SuperAdmin/ModalFunciones");
const TerminosYCondiciones = () =>
  import("@/views/Helexium/SuperAdmin/TerminosYCondiciones");

// Plan de Mejoramiento👇
const PlanMejoramiento = () =>
  import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento");
const PlanMejoramiento_agregar = () =>
  import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento_agregar");
// const PlanMejoramiento_finalizados = () =>
//   import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento_finalizados");
// Plan de Mejoramiento☝︝

// mis actividades👇
const MisActividades = () =>
  import("@/views/Helexium/MisActividades/MisActividades");
// mis actividades☝︝

// Onboarding👇
const Onboarding = () => import("@/views/Helexium/Onboarding/Onboarding");
const Onboarding_admin = () =>
  import("@/views/Helexium/Onboarding/Onboarding_admin");
const Onboarding_agregar = () =>
  import("@/views/Helexium/Onboarding/Onboarding_agregar");

// Onboarding☝︝

// Capacitaciones👇
const RegistroCapacitaciones = () =>
  import("@/views/Helexium/Capacitaciones/RegistroCapacitaciones");
const RegistroCapacitaciones_agregar = () =>
  import("@/views/Helexium/Capacitaciones/RegistroCapacitaciones_agregar");
const MisCapacitaciones = () =>
  import("@/views/Helexium/Capacitaciones/MisCapacitaciones");

// apacitaciones☝︝

// GPS
const RegistroImeiGPS = () => import("@/views/Helexium/GPS/RegistroImeiGPS");
const gps_agregar = () => import("@/views/Helexium/GPS/gps_agregar");

// registro servicios
const RegistroServicios = () =>
  import("@/views/Helexium/RegistroServicios/RegistroServicios");

// // const dashboard_nuevo_prueba = () => import('@/views/Helexium/dashboard_nuevo_prueba/dashboard_nuevo_prueba')
// //Adding layouts router.
// // const BlankLayout = () => import("@/layouts/BlankLayout")

// const Layout10 = () => import("@/layouts/backend/Layout-10");
// const Layout10 = () => import("@/layouts/backend/Layout-9");

//Tickets

const Chat = () => import("@/views/backend/App/Mail/EmailListing");

const Tickets = () => import("@/views/Helexium/Tickets/ListarTickets");
const AgregarTickets = () => import("@/views/Helexium/Tickets/AgregarTickets");
const ChatTicket = () => import("@/views/Helexium/Tickets/ChatTicket");

const ControlAccesosPersona = () =>
  import("@/views/Helexium/ControlAccesos/ControlAccesosPersona");

const Map = () => import("@/views/Helexium/SuperAdmin/Map");

const MapGps = () => import("@/views/Helexium/SuperAdmin/MapGps");

// market and canjes
const Canjes = () => import("@/views/Helexium/Market/Canjes");
const Market = () => import("@/views/Helexium/Market/Market");
const ManagerMarket = () => import("@/views/Helexium/Market/ManagerMarket");

//Historial Clinico

//Medico Ocupacional
const MedOcuLaboral = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuLaboral");
const MedOcuEventos = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuEventos");
const MedOcuFamiliar = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuFamiliar");
const MedOcuPersonal = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuPersonal");
const MedOcuHabitos = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuHabitos");
const MedOcuFisico = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuFisico");
const MedOcuOtros = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuOtros");
7;
//Prueba Charts
const ReportesGraficos = () =>
  import("@/views/Helexium/ReportesConApex/ReporteGraficos");
const ReportePerfilCargo = () =>
  import("@/views/Helexium/ReportesConApex/ReportePerfilCargo");
const ReporteRegistros = () =>
  import("@/views/Helexium/ReportesConApex/ReporteRegistros");

// ERP
const ErpDashboard = () =>
  import("@/views/Helexium/Erp/Contabilidad/Dashboard/Dashboard");
const ErpArticulos = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Articulos");
const ErpArticuloNuevo = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Articulo_nuevo");
const ErpArticuloEditar = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Articulo_editar");
const ErpVentasFacturas = () =>
  import("@/views/Helexium/Erp/Contabilidad/Ventas/Facturas/Facturas");
const ErpVentasNuevaFactura = () =>
  import("@/views/Helexium/Erp/Contabilidad/Ventas/Facturas/Factura_nuevo");
const ErpVentasClientes = () =>
  import("@/views/Helexium/Erp/Contabilidad/Ventas/Clientes/Clientes");
const ErpVentasNuevoCliente = () =>
  import("@/views/Helexium/Erp/Contabilidad/Ventas/Clientes/Cliente_nuevo");
const ErpComprasRecibos = () =>
  import("@/views/Helexium/Erp/Contabilidad/Compras/Recibos/Recibos");
const ErpComprasNuevoRecibo = () =>
  import("@/views/Helexium/Erp/Contabilidad/Compras/Recibos/Recibo_nuevo");
const ErpComprasProveedores = () =>
  import("@/views/Helexium/Erp/Contabilidad/Compras/Proveedores/Proveedores");
const ErpComprasNuevoProveedor = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Compras/Proveedores/Proveedor_nuevo"
  );
const ErpBancoCuentas = () =>
  import("@/views/Helexium/Erp/Contabilidad/Banco/Cuentas/Cuentas");
const ErpBancoNuevaCuenta = () =>
  import("@/views/Helexium/Erp/Contabilidad/Banco/Cuentas/Cuentas_nuevo");
const ErpBancoEditarCuenta = () =>
  import("@/views/Helexium/Erp/Contabilidad/Banco/Cuentas/Cuentas_editar.vue");
const ErpBancoTransacciones = () =>
  import("@/views/Helexium/Erp/Contabilidad/Banco/Transacciones/Transacciones");
const ErpBancoTransaccionesNuevosIngresos = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Transacciones/Ingresos_nuevo"
  );
const ErpBancoTransaccionesNuevosGastos = () =>
  import("@/views/Helexium/Erp/Contabilidad/Banco/Transacciones/Gastos_nuevo");
const ErpBancoTransferencias = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Transferencias/Transferencias"
  );
const ErpBancoNuevaTransferencia = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Transferencias/Transferencia_nuevo"
  );
const ErpBancoEditarTransferencia = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Transferencias/Transferencia_editar"
  );
const ErpBancoConciliaciones = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Conciliaciones/Conciliaciones"
  );
const ErpBancoNuevaConciliacion = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Banco/Conciliaciones/Conciliacion_nuevo"
  );
const ErpInformes = () =>
  import("@/views/Helexium/Erp/Contabilidad/Informes/Informes");
const ErpNuevoInforme = () =>
  import("@/views/Helexium/Erp/Contabilidad/Informes/Informe_nuevo");
const ErpCategoria = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Categoria");
const ErpCrearCategoria = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Categoria_nueva");
const ErpEditarCategoria = () =>
  import("@/views/Helexium/Erp/Contabilidad/Articulos/Categoria_editar");
// Registro Contable
const ErpRegistroCuentas = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Cuentas/Cuentas"
  );
const ErpRegistroCrearCuenta = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Cuentas/Cuenta_crear"
  );
const ErpRegistroEditarCuenta = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Cuentas/Cuenta_editar"
  );
const ErpRegistroDetallesCuenta = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Cuentas/Cuenta_detalles"
  );
const ErpRegistroRegistro = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Registros/Registro"
  );
const ErpRegistroCrearRegistros = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Registros/Registro_crear"
  );
const ErpRegistroEditarRegistros = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Registros/Registro_editar"
  );
const ErpRegistroDetallesRegistro = () =>
  import(
    "@/views/Helexium/Erp/Contabilidad/Registros_contables/Registros/Registro_detalles"
  );

const NotFound = () => import("@/views/Helexium/_404/index");
const Terminales = () => import("@/views/Helexium/Terminales");

const ContainerEncuesta = () =>
  import("@/views/Helexium/PESV/ContainerEncuesta");

const CategorizacionPESV = () =>
  import("@/views/Helexium/PESV/Categorizacion/Categorizacion");

const ControlAccesos = () =>
  import("@/views/Helexium/SuperAdmin/ControlAccesos");

const ControlAccesosAddJson = () =>
  import("@/views/Helexium/SuperAdmin/ControlAccesosAddJson");
const GestionVistasHelexium = () =>
  import("@/views/Helexium/SuperAdmin/GestionVistasHelexium");

const NoAccess = () => import("@/views/Helexium/_404/NoAccess");

const Calendario = () => import("@/views/Helexium/Calendario/Calendario");

Vue.use(VueRouter);

const helexiumRoutes = () => [
  {
    path: "calendario",
    name: "calendario",
    meta: {
      name: "Calendario",
      requiresAuth: true,
      public: true,
      sinLoader: true,
    },
    component: Calendario,
  },
  {
    path: "NoAccess",
    name: "NoAccess",
    meta: { name: "NoAccess" },
    component: NoAccess,
  },
  //Control de Accesos
  {
    path: "SuperAdmin/ControlAccesos",
    name: "ControlAccesos",
    meta: { requiresAuth: true, name: "ControlAccesos" },
    component: ControlAccesos,
  },
  {
    path: "SuperAdmin/ControlAccesosAddJson",
    name: "ControlAccesosAddJson",
    meta: { requiresAuth: true, name: "ControlAccesosAddJson"},
    component: ControlAccesosAddJson,
  },
  {
    path: "SuperAdmin/GestionVistasHelexium",
    name: "GestionVistasHelexium",
    meta: { requiresAuth: true, name: "ControlAccesosAddJson"},
    component: GestionVistasHelexium,
  },

  // categorizacion pesv
  {
    path: "PESV/Categorizacion",
    name: "CategorizacionPESV",
    meta: { requiresAuth: true, name: "CategorizacionPESV" },
    component: CategorizacionPESV,
  },
  // Encuestas pesv
  {
    path: "PESV/encuestas/:idEncuesta",
    name: "ContainerEncuesta",
    meta: { requiresAuth: true, name: "ContainerEncuesta" },
    component: ContainerEncuesta,
    props: (route) => ({
      idEncuesta: route.params.idEncuesta,
      idVigilado: route.query.vigilado,
      numeroReporte: route.query.reporte,
    }),
  },

  // ERP
  {
    path: "Erp/Contabilidad/RegistrosContables/DetallesRegistro/:id",
    name: "DetallesRegistro",
    meta: { requiresAuth: true, name: "DetallesRegistro" },
    component: ErpRegistroDetallesRegistro,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/EditarRegistro/:id",
    name: "EditarRegistros",
    meta: { requiresAuth: true, name: "EditarRegistros" },
    component: ErpRegistroEditarRegistros,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/CrearRegistros",
    name: "CrearRegistros",
    meta: { requiresAuth: true, name: "Registro" },
    component: ErpRegistroCrearRegistros,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/Registros",
    name: "Registro",
    meta: { requiresAuth: true, name: "Registro" },
    component: ErpRegistroRegistro,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/DetallesCuenta/:id",
    name: "DetallesCuenta",
    meta: { requiresAuth: true, name: "DetallesCuenta" },
    component: ErpRegistroDetallesCuenta,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/EditarCuenta/:id",
    name: "EditarCuenta",
    meta: { requiresAuth: true, name: "EditarCuenta" },
    component: ErpRegistroEditarCuenta,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/CrearCuenta",
    name: "CrearCuenta",
    meta: { requiresAuth: true, name: "CrearCuenta" },
    component: ErpRegistroCrearCuenta,
  },
  {
    path: "Erp/Contabilidad/RegistrosContables/Cuentas",
    name: "Cuentas",
    meta: { requiresAuth: true, name: "Cuentas" },
    component: ErpRegistroCuentas,
  },
  {
    path: "Erp/Contabilidad/NuevoInforme",
    name: "NuevoInforme",
    meta: { requiresAuth: true, name: "NuevoInforme" },
    component: ErpNuevoInforme,
  },
  {
    path: "Erp/Contabilidad/Informes",
    name: "Informes",
    meta: { requiresAuth: true, name: "Informes" },
    component: ErpInformes,
  },
  {
    path: "Erp/Contabilidad/Banco/NuevaConciliacion",
    name: "NuevaConciliacion",
    meta: { requiresAuth: true, name: "NuevaConciliacion" },
    component: ErpBancoNuevaConciliacion,
  },
  {
    path: "Erp/Contabilidad/Banco/Conciliaciones",
    name: "Conciliaciones",
    meta: { requiresAuth: true, name: "Conciliaciones" },
    component: ErpBancoConciliaciones,
  },
  {
    path: "Erp/Contabilidad/Banco/EditTransferencia",
    name: "EditarTransferencia",
    meta: { requiresAuth: true, name: "EditarTransferencia" },
    component: ErpBancoEditarTransferencia,
  },
  {
    path: "Erp/Contabilidad/Banco/NuevaTransferencia",
    name: "NuevaTransferencia",
    meta: { requiresAuth: true, name: "NuevaTransferencia" },
    component: ErpBancoNuevaTransferencia,
  },
  {
    path: "Erp/Contabilidad/Banco/Transferencias",
    name: "Transferencias",
    meta: { requiresAuth: true, name: "Transferencias" },
    component: ErpBancoTransferencias,
  },
  {
    path: "Erp/Contabilidad/Banco/Transacciones/NuevosGastos",
    name: "NuevosGastos",
    meta: { requiresAuth: true, name: "NuevosGastos" },
    component: ErpBancoTransaccionesNuevosGastos,
  },
  {
    path: "Erp/Contabilidad/Banco/Transacciones/NuevosIngresos",
    name: "NuevosIngresos",
    meta: { requiresAuth: true, name: "NuevosIngresos" },
    component: ErpBancoTransaccionesNuevosIngresos,
  },
  {
    path: "Erp/Contabilidad/Banco/Transacciones",
    name: "Transacciones",
    meta: { requiresAuth: true, name: "Transacciones" },
    component: ErpBancoTransacciones,
  },
  {
    path: "Erp/Contabilidad/Banco/NuevaCuenta",
    name: "NuevaCuenta",
    meta: { requiresAuth: true, name: "NuevaCuenta" },
    component: ErpBancoNuevaCuenta,
  },
  {
    path: "Erp/Contabilidad/Banco/EditarCuenta/:id",
    name: "EditarCuenta",
    meta: { requiresAuth: true, name: "EditarCuenta" },
    component: ErpBancoEditarCuenta,
  },
  {
    path: "Erp/Contabilidad/Banco/Cuentas",
    name: "Cuentas",
    meta: { requiresAuth: true, name: "Cuentas" },
    component: ErpBancoCuentas,
  },
  {
    path: "Erp/Contabilidad/Compras/NuevoProveedor",
    name: "NuevoProveedor",
    meta: { requiresAuth: true, name: "NuevoProveedor" },
    component: ErpComprasNuevoProveedor,
  },
  {
    path: "Erp/Contabilidad/Compras/Proveedores",
    name: "Proveedores",
    meta: { requiresAuth: true, name: "Proveedores" },
    component: ErpComprasProveedores,
  },
  {
    path: "Erp/Contabilidad/Compras/NuevoRecibo",
    name: "NuevoRecibo",
    meta: { requiresAuth: true, name: "NuevoRecibo" },
    component: ErpComprasNuevoRecibo,
  },
  {
    path: "Erp/Contabilidad/Compras/Recibos",
    name: "Recibos",
    meta: { requiresAuth: true, name: "Recibos" },
    component: ErpComprasRecibos,
  },
  {
    path: "Erp/Contabilidad/Ventas/NuevoCliente",
    name: "NuevoCliente",
    meta: { requiresAuth: true, name: "NuevoCliente" },
    component: ErpVentasNuevoCliente,
  },
  {
    path: "Erp/Contabilidad/Ventas/Clientes",
    name: "Clientes",
    meta: { requiresAuth: true, name: "Clientes" },
    component: ErpVentasClientes,
  },
  {
    path: "Erp/Contabilidad/Ventas/NuevaFactura",
    name: "NuevaFactura",
    meta: { requiresAuth: true, name: "NuevaFactura" },
    component: ErpVentasNuevaFactura,
  },
  {
    path: "Erp/Contabilidad/Ventas/Facturas",
    name: "Facturas",
    meta: { requiresAuth: true, name: "Facturas" },
    component: ErpVentasFacturas,
  },
  {
    path: "Erp/Contabilidad/EditarCategoria/:id",
    name: "EditarCategoria",
    meta: { requiresAuth: true, name: "EditarCategoria" },
    component: ErpEditarCategoria,
  },
  {
    path: "Erp/Contabilidad/NuevaCategoria",
    name: "CrearCategoria",
    meta: { requiresAuth: true, name: "CrearCategoria" },
    component: ErpCrearCategoria,
  },
  {
    path: "Erp/Contabilidad/Categorias",
    name: "Categorias",
    meta: { requiresAuth: true, name: "Categorias" },
    component: ErpCategoria,
  },
  {
    path: "Erp/Contabilidad/EditarArticulo/:id",
    name: "EditarArticulo",
    meta: { requiresAuth: true, name: "EditarArticulo" },
    component: ErpArticuloEditar,
  },
  {
    path: "Erp/Contabilidad/NuevoArticulo",
    name: "NuevoArticulo",
    meta: { requiresAuth: true, name: "NuevoArticulo" },
    component: ErpArticuloNuevo,
  },
  {
    path: "Erp/Contabilidad/Articulos",
    name: "Articulos",
    meta: { requiresAuth: true, name: "Articulos" },
    component: ErpArticulos,
  },
  {
    path: "Erp/Contabilidad/Dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true, name: "Dashboard" },
    component: ErpDashboard,
  },

  //Graficos
  {
    path: "ReportesConApex/Graficos",
    name: "Graficos",
    meta: { requiresAuth: true, name: "Graficos" },
    component: ReportesGraficos,
  },
  {
    path: "ReportesConApex/PerfilCargo",
    name: "PerfilCargo",
    meta: { requiresAuth: true, name: "PerfilCargo" },
    component: ReportePerfilCargo,
  },
  {
    path: "ReportesConApex/Registros",
    name: "Registros",
    meta: { requiresAuth: true, name: "Registros" },
    component: ReporteRegistros,
  },
  //Graficos
  {
    path: "MiAvatar",
    name: "MiAvatar",
    component: () => import("@/views/Helexium/MiAvatar/MiAvatar"),
  },
  {
    path: "terminales",
    name: "Terminales",
    component: Terminales,
  },
  {
    path: "CreacionDeAvatar",
    name: "CreacionDeAvatar",
    component: () => import("@/views/Helexium/MiAvatar/CreacionDeAvatar"),
  },
  // market and canjes

  //-------------- AGREGAR TALENTO HUMANO GAMIFICACIÓN ADMINISTRADORES Y SUPERADMIN --------------//
  {
    path: "managerMarket",
    name: "managerMarket",
    meta: { requiresAuth: true, name: "managerMarket " },
    component: ManagerMarket,
  },
  {
    path: "canjes",
    name: "canjes",
    meta: { requiresAuth: true, name: "canjes ", public: true },
    component: Canjes,
  },
  {
    path: "market",
    name: "market",
    meta: { requiresAuth: true, name: "market", public: true },
    component: Market,
  },

  //Historial Clinico
  //Medico Ocupacional
  {
    path: "MedicoOcupacional/MedOcuLaboral",
    name: "MedOcuLaboral",
    meta: { requiresAuth: true, name: "MedOcuLaboral" },
    component: MedOcuLaboral,
  },
  {
    path: "MedicoOcupacional/MedOcuEventos",
    name: "MedOcuEventos",
    meta: { requiresAuth: true, name: "MedOcuEventos" },
    component: MedOcuEventos,
  },
  {
    path: "MedicoOcupacional/MedOcuFamiliar",
    name: "MedOcuFamiliar",
    meta: { requiresAuth: true, name: "MedOcuFamiliar" },
    component: MedOcuFamiliar,
  },

  {
    path: "MedicoOcupacional/MedOcuPersonal",
    name: "MedOcuPersonal",
    meta: { requiresAuth: true, name: "MedOcuPersonal" },
    component: MedOcuPersonal,
  },
  {
    path: "MedicoOcupacional/MedOcuHabitos",
    name: "MedOcuHabitos",
    meta: { requiresAuth: true, name: "MedOcuHabitos" },
    component: MedOcuHabitos,
  },
  {
    path: "MedicoOcupacional/MedOcuFisico",
    name: "MedOcuFisico",
    meta: { requiresAuth: true, name: "MedOcuFisico" },
    component: MedOcuFisico,
  },
  {
    path: "MedicoOcupacional/MedOcuOtros",
    name: "MedOcuOtros",
    meta: { requiresAuth: true, name: "MedOcuOtros" },
    component: MedOcuOtros,
  },

  // registro servicios
  {
    path: "registroServicios",
    name: "registroServicios",
    meta: { requiresAuth: true, name: "registroServicios" },
    component: RegistroServicios,
  },
  // GPS
  {
    path: "gps",
    name: "gps",
    meta: { requiresAuth: true, name: "gps" },
    component: RegistroImeiGPS,
  },
  {
    path: "gps/gps_agregar",
    name: "gps_agregar",
    meta: { requiresAuth: true, name: "gps_agregar", sinLoader: true },
    component: gps_agregar,
  },
  // map
  {
    path: "map",
    name: "map",
    meta: { requiresAuth: true, name: "map" },
    component: Map,
  },
  {
    path: "mapGps",
    name: "mapgps",
    meta: { requiresAuth: true, name: "mapgps" },
    component: MapGps,
  },
  {
    path: "ControlAccesosPersona/:PersonaId",
    name: "ControlAccesosPersona",
    meta: { requiresAuth: true, name: "ControlAccesosPersona" },
    component: ControlAccesosPersona,
  },

  {
    path: "chat",
    name: "app.chat",
    meta: { requiresAuth: true, name: "Chat" },
    component: Chat,
  },
  // plan de mejoramiento👇
  {
    path: "PlanMejoramiento",
    name: "PlanMejoramiento",
    meta: { requiresAuth: true, name: "PlanMejoramiento" },
    component: PlanMejoramiento,
  },
  {
    path: "PlanMejoramiento_agregar",
    name: "PlanMejoramiento_agregar",
    meta: {
      requiresAuth: true,
      name: "PlanMejoramiento_agregar",
      sinLoader: true,
    },
    component: PlanMejoramiento_agregar,
  },
  // {
  //   path: "PlanMejoramiento_finalizados",
  //   name: "PlanMejoramiento_finalizados",
  //   meta: { requiresAuth:true, name: "PlanMejoramiento_finalizados" },
  //   component: PlanMejoramiento_finalizados,
  // },
  // plan de mejoramiento☝︝
  // mis actividades👇
  {
    path: "perfil/MisActividades",
    name: "MisActividades",
    meta: { requiresAuth: true, name: "MisActividades" },
    component: MisActividades,
  },
  // mis actividades☝︝
  // onboarding👇
  {
    path: "Onboarding",
    name: "Onboarding",
    meta: { requiresAuth: true, name: "Onboarding" },
    component: Onboarding,
  },
  {
    path: "Onboarding_admin",
    name: "Onboarding_admin",
    meta: { requiresAuth: true, name: "Onboarding_admin" },
    component: Onboarding_admin,
  },
  {
    path: "Onboarding_agregar",
    name: "Onboarding_agregar",
    meta: { requiresAuth: true, name: "Onboarding_agregar", sinLoader: true },
    component: Onboarding_agregar,
  },
  // onboarding☝︝

  // capacitaciones👇
  {
    path: "Capacitaciones/RegistroCapacitaciones",
    name: "RegistroCapacitaciones",
    meta: {
      requiresAuth: true,
      name: "RegistroCapacitaciones",
    },
    component: RegistroCapacitaciones,
  },
  {
    path: "Capacitaciones/RegistroCapacitaciones_agregar",
    name: "RegistroCapacitaciones_agregar",
    meta: {
      requiresAuth: true,
      name: "RegistroCapacitaciones_agregar",
      sinLoader: true,
    },
    component: RegistroCapacitaciones_agregar,
  },
  {
    path: "Capacitaciones/MisCapacitaciones",
    name: "MisCapacitaciones",
    meta: { requiresAuth: true, name: "MisCapacitaciones" },
    component: MisCapacitaciones,
  },
  // capacitaciones☝︝
  {
    path: "ReadXlsFile",
    name: "ReadXlsFile",
    meta: { requiresAuth: true, name: "ReadXlsFile" },
    component: ReadXlsFile,
  },
  {
    path: "UploadHonorarios",
    name: "UploadHonorarios",
    meta: { requiresAuth: true, name: "UploadHonorarios" },
    component: UploadHonorarios,
  },
  {
    path: "persona",
    name: "persona",
    meta: { requiresAuth: true, name: "persona" },
    component: Persona,
  },
  {
    path: "empresa",
    name: "empresa",
    meta: { requiresAuth: true, name: "empresa" },
    component: Empresa,
  },
  {
    path: "SGSST",
    name: "SGSST",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSST,
  },
  {
    path: "SGSST/Profesiograma",
    name: "SGSST/Profesiograma",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSSTProfesiograma,
  },
  {
    path: "SGSST/ListarProfesiograma",
    name: "SGSST/ListarProfesiograma",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSSTListarProfesiograma,
  },
  {
    path: "SGSST/InspeccionElementos",
    name: "SGSST/InspeccionElementos",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSSTInspeccionElementos,
  },
  {
    path: "SGSST/ListarInspeccionElementos",
    name: "SGSST/ListarInspeccionElementos",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSSTListarInspeccionElementos,
  },

  {
    path: "PESV",
    name: "PESV",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PESV,
  },
  {
    path: "Sucursales",
    name: "Sucursales",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Sucursales,
  },
  {
    path: "Departamentos",
    name: "Departamentos",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Departamentos,
  },
  {
    path: "Areas",
    name: "Areas",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Areas,
  },
  {
    path: "Competencias",
    name: "Competencias",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Competencias,
  },
  {
    path: "Medallas",
    name: "Medallas",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Medallas,
  },
  {
    path: "PESV/HojaDeVidaVehiculo",
    name: "HojaDeVidaVehiculo",
    meta: { requiresAuth: true, name: "Data Table " },
    component: HojaDeVidaVehiculo,
  },
  {
    path: "PESV/RegistroDeVehiculos",
    name: "RegistroDeVehiculos",
    meta: { requiresAuth: true, name: "Data Table " },
    component: RegistroDeVehiculos,
  },
  {
    path: "PESV/Indicadores",
    name: "IndicadoresPESV",
    meta: { requiresAuth: true, name: "Data Table " },
    component: IndicadoresPESV,
  },
  {
    path: "PeligrosYRiesgos",
    name: "PeligroYRiesgos",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PeligrosYRiesgos,
  },
  {
    path: "PESV/FichaIndicadores",
    name: "PESVFichaIndicadores",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PESVFichaIndicadores,
  },
  {
    path: "InspeccionVehiculo",
    name: "InspeccionVehiculo",
    meta: { requiresAuth: true, name: "Data Table " },
    component: InspeccionVehiculo,
  },
  {
    path: "InspeccionVehiculo/listado-inspecciones/:placa/:vehiculoid",
    name: "ListadoInspecciones",
    component: VisualizarInspecciones,
    meta: { requiresAuth: true, sinLoader: true },
  },
  {
    path: "InspeccionVehiculo/nueva-inspeccion/:placa/:vehiculoid/:idinspeccion?",
    name: "ContentFormInspeccionNew",
    component: ContentFormInspeccion,
    meta: { requiresAuth: true, sinLoader: true },
  },
  {
    path: "InspeccionVehiculo/editar-inspeccion/:placa/:vehiculoid/:idinspeccion",
    name: "ContentFormInspeccionEdit",
    component: ContentFormInspeccion,
    meta: { requiresAuth: true, sinLoader: true },
  },
  {
    path: "InspeccionVehiculo/detalles-inspeccion/:placa/:vehiculoid/:idinspeccion",
    name: "InspeccionDetails",
    component: ContentFormInspeccion,
    meta: { requiresAuth: true, sinLoader: true },
  },
  {
    path: "Empresas_agregar",
    name: "Empresas_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Empresas_agregar,
  },
  {
    path: "Empresas_editar/:id",
    name: "Empresas_editar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Empresas_editar,
  },
  {
    path: "Persona_agregar",
    name: "Persona_agregar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Persona_agregar,
  },
  {
    path: "Persona_historia_laboral/:id",
    name: "Persona_historial_laboral",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Persona_historial_laboral,
  },
  {
    path: "Persona_editar/:id",
    name: "Persona_editar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Persona_editar,
  },
  {
    path: "Sucursales_agregar",
    name: "Sucursales_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Sucursales_agregar,
  },
  {
    path: "Sucursales_editar/:id",
    name: "Sucursales_editar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Sucursales_editar,
  },
  {
    path: "Departamentos_agregar",
    name: "Departamentos_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Departamentos_agregar,
  },
  {
    path: "Departamentos_editar/:id",
    name: "Departamentos_editar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Departamentos_editar,
  },
  {
    path: "Areas_agregar",
    name: "Areas_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Areas_agregar,
  },
  {
    path: "Areas_editar/:id",
    name: "Areas_editar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Areas_editar,
  },
  {
    path: "Competencias_agregar",
    name: "Competencias_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Competencias_agregar,
  },
  {
    path: "Competencias_editar/:id",
    name: "Competencias_editar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Competencias_editar,
  },
  {
    path: "RegistroDeIncapacidades",
    name: "RegistroDeIncapacidades",
    meta: { requiresAuth: true, name: "Data Table " },
    component: RegistroDeIncapacidades,
  },
  {
    path: "RegistroDeAccidentes",
    name: "RegistroDeAccidentes",
    meta: { requiresAuth: true, name: "Data Table " },
    component: RegistroDeAccidentes,
  },
  {
    path: "finalizadas",
    name: "finalizadas",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Finalizadas,
  },
  {
    path: "caracterizacionEnfermedadLaboral",
    name: "CaracterizacionEnfermedadLaboral",
    meta: { requiresAuth: true, name: "Data Table " },
    component: CaracterizacionEnfermedadLaboral,
  },
  {
    path: "hoja_vida/:id?",
    name: "hoja_vida",
    meta: { requiresAuth: true, name: "Data Table " },
    component: hoja_vida,
  },
  {
    path: "perfil/cargo",
    name: "PerfilCargo",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PerfilCargo,
  },
  {
    path: "perfil/medallas",
    name: "PerfilMedallas",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PerfilMedallas,
  },
  {
    path: "perfil/comentarios",
    name: "PerfilComentarios",
    meta: { requiresAuth: true, name: "Data Table " },
    component: PerfilComentarios,
  },
  {
    path: "Ciclo_evaluaciones_agregar",
    name: "Ciclo_evaluaciones_agregar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Ciclo_evaluaciones_agregar,
  },
  {
    path: "SGSST/FichaIndicadores",
    name: "SGSSTFichaIndicadores",
    meta: { requiresAuth: true, name: "Data Table " },
    component: SGSSTFichaIndicadores,
  },
  {
    path: "Pendientes",
    name: "Pendientes",
    meta: { requiresAuth: true, name: "Data Table ", public: true },
    component: Pendientes,
  },

  {
    path: "Medallas_agregar",
    name: "Medallas_agregar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Medallas_agregar,
  },
  {
    path: "Medallas_editar/:id",
    name: "Medallas_editar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Medallas_editar,
  },

  // reportes

  {
    path: "Reportes",
    name: "Reportes",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Reportes,
  },
  {
    path: "Reportes_agregar",
    name: "Reportes_agregar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Reportes_agregar,
  },
  {
    path: "Reportes_editar/:id",
    name: "Reportes_editar",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Reportes_editar,
  },

  {
    path: "Preguntas",
    name: "Preguntas",
    meta: { requiresAuth: true, name: "Preguntas" },
    component: Preguntas,
  },
  {
    path: "Preguntas_agregar/:id",
    name: "Preguntas_agregar",
    meta: { requiresAuth: true, name: "Preguntas_agregar" },
    component: Preguntas_agregar,
  },
  {
    path: "Preguntas_lista/:id",
    name: "Preguntas_lista",
    meta: { requiresAuth: true, name: "Preguntas_lista" },
    component: Preguntas_lista,
  },
  {
    path: "Cargos",
    name: "Cargos",
    meta: { requiresAuth: true, name: "Cargos" },
    component: Cargos,
  },
  {
    path: "Cargos_agregar",
    name: "Cargos_agregar",
    meta: { requiresAuth: true, name: "Cargos_agregar" },
    component: Cargos_agregar,
  },
  {
    path: "CrearCargoV2",
    name: "CrearCargoV2",
    meta: { requiresAuth: true, name: "CrearCargoV2" },
    component: () => import("@/views/Helexium/Cargos/CrearCargoV2"),
  },

  {
    path: "Cargos_editar/:id",
    name: "Cargos_editar",
    meta: { requiresAuth: true, name: "Cargos_editar", sinLoader: true },
    component: Cargos_editar,
  },

  {
    path: "Cargos_perfil/:id",
    name: "Cargos_perfil",
    meta: { requiresAuth: true, name: "Cargos_perfil" },
    component: Cargos_perfil,
  },

  {
    path: "CargoPerfilV2/:id",
    name: "CargoPerfilV2",
    meta: { requiresAuth: true, name: "CargoPerfilV2" },
    component: () => import("@/views/Helexium/Cargos/CargoPerfilV2"),
  },

  //academia y cursos
  {
    path: "Ayuda/Academia/AcademiaVideos/:id",
    name: "AcademiaVideos",
    meta: { requiresAuth: true, name: "Data Table ", public: true },
    component: AcademiaVideos,
  },
  {
    path: "Ayuda/Academia",
    name: "Cursos",
    meta: { requiresAuth: true, name: "Cursos", public: true },
    component: Cursos,
  },
  {
    path: "Ayuda/Academia/InfoCurso/:id",
    name: "InfoCurso",
    meta: { requiresAuth: true, name: "InfoCurso", public: true },
    component: InfoCurso,
  },
  {
    path: "Ayuda/Soporte",
    name: "SoporteVideos",
    meta: { requiresAuth: true, name: "Data Table ", public: true },
    component: SoporteVideos,
  },
  {
    path: "Ayuda/videoManager",
    name: "videoManager",
    meta: { requiresAuth: true, name: "videoManager" },
    component: VideoManager,
  },
  {
    path: "Ciclo_evaluaciones",
    name: "Ciclo_evaluaciones",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Ciclo_evaluaciones,
  },
  {
    path: "Ciclo_evaluaciones_editar/:id",
    name: "Ciclo_evaluaciones_editar",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Ciclo_evaluaciones_editar,
  },
  {
    path: "perfil/enviar_medallas",
    name: "enviar_medallas",
    meta: { requiresAuth: true, name: "Data Table ", sinLoader: true },
    component: Enviar_medallas,
  },
  {
    path: "evaluacion/:id/:ciclo",
    name: "evaluacion",
    meta: { requiresAuth: true, name: "Data Table ", public: true },
    component: evaluacion,
  },

  {
    path: "Tipo_Identidad",
    name: "Tipo_Identidad",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Tipo_Identidad,
  },
  {
    path: "Tipo_Competencias",
    name: "Tipo_Competencias",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Tipo_Competencias,
  },
  {
    path: "Tipo_Niveles_cargo",
    name: "Tipo_Niveles_cargo",
    meta: { requiresAuth: true, name: "Data Table " },
    component: Tipo_Niveles_cargo,
  },
  {
    path: "dataLake",
    name: "dataLake",
    meta: { requiresAuth: true, name: "dataLake " },
    component: DataLake,
  },

  {
    path: "Tickets",
    name: "Tickets",
    meta: { requiresAuth: true, name: "Tickets ", public: true },
    component: Tickets,
  },

  {
    path: "AgregarTickets",
    name: "AgregarTickets",
    meta: { requiresAuth: true, name: "AgregarTickets ", public: true },
    component: AgregarTickets,
  },

  {
    path: "ChatTicket/:userid/:id",
    name: "ChatTicket",
    meta: { requiresAuth: true, name: "ChatTicket ", public: true },
    component: ChatTicket,
  },

  {
    path: "administradorDeVacantes",
    name: "AdministradorDeVacantes",
    meta: { requiresAuth: true, name: "AdministradorDeVacantes " },
    component: AdministradorDeVacantes,
  },
  {
    path: "NuevaVacante",
    name: "NuevaVacante",
    meta: { requiresAuth: true, name: "NuevaVacante", sinLoader: true },
    component: NuevaVacante,
  },
  {
    path: "EditarVacante/:id",
    name: "EditarVacante",
    meta: { requiresAuth: true, name: "EditarVacante", sinLoader: true },
    component: EditarVacante,
  },
  {
    path: "ListaDePostulantes/:vacanteId",
    name: "ListaDePostulantes",
    meta: { requiresAuth: true, name: "ListaDePostulantes" },
    component: ListaDePostulantes,
  },
  {
    path: "ProcesoDePreseleccion/:vacanteId",
    name: "ProcesoDePreseleccion",
    meta: {
      requiresAuth: true,
      name: "ProcesoDePreseleccion",
      sinLoader: true,
    },
    component: ProcesoDePreseleccion,
  },
  {
    path: "NuevaPostulacion/:vacanteId",
    name: "NuevaPostulacion",
    meta: { requiresAuth: true, name: "NuevaPostulacion" },
    component: NuevaPostulacion,
  },
  {
    path: "RegistroDePostulacion/:id",
    name: "RegistroDePostulacion",
    meta: {
      requiresAuth: true,
      name: "RegistroDePostulacion",
      sinLoader: true,
    },
    component: RegistroDePostulacion,
  },
  {
    path: "ProcesoDeSeleccion/:vacanteId",
    name: "ProcesoDeSeleccion",
    meta: { requiresAuth: true, name: "ProcesoDeSeleccion", sinLoader: true },
    component: ProcesoDeSeleccion,
  },
  {
    path: "EvaluarDocumentosDeSeleccion/:vacanteId/:postulacionId",
    name: "EvaluarDocumentosDeSeleccion",
    meta: {
      requiresAuth: true,
      name: "EvaluarDocumentosDeSeleccion",
      sinLoader: true,
    },
    component: EvaluarDocumentosDeSeleccion,
  },
  {
    path: "VinculacionDePostulantes/:vacanteId",
    name: "VinculacionDePostulantes",
    meta: {
      requiresAuth: true,
      name: "VinculacionDePostulantes",
      sinLoader: true,
    },
    component: VinculacionDePostulantes,
  },

  {
    path: "Prestaciones",
    name: "Prestaciones",
    meta: { requiresAuth: true, name: "Prestaciones" },
    component: Prestaciones,
  },
  {
    path: "MisHonorarios",
    name: "MisHonorarios",
    meta: { requiresAuth: true, name: "MisHonorarios" },
    component: MisHonorarios,
  },
  {
    path: "Porcentajes",
    name: "Porcentajes",
    meta: { requiresAuth: true, name: "Porcentajes" },
    component: Porcentajes,
  },
  {
    path: "Subvencion",
    name: "Subvencion",
    meta: { requiresAuth: true, name: "Subvencion" },
    component: Subvencion,
  },

  {
    path: "numeraciones",
    name: "numeraciones",
    meta: { requiresAuth: true, name: "numeraciones" },
    component: Numeraciones,
  },
  {
    path: "numeracion_agregar",
    name: "numeracion_agregar",
    meta: { requiresAuth: true, name: "numeracion_agregar" },
    component: Numeracion_agregar,
  },
  {
    path: "numeracion_editar/:id",
    name: "numeracion_editar",
    meta: { requiresAuth: true, name: "numeracion_editar" },
    component: Numeracion_editar,
  },
  {
    path: "devengados",
    name: "devengados",
    meta: { requiresAuth: true, name: "devengados" },
    component: Devengados,
  },
  {
    path: "deducciones",
    name: "deducciones",
    meta: { requiresAuth: true, name: "deducciones" },
    component: Deducciones,
  },
  {
    path: "nominas",
    name: "nominas",
    meta: { requiresAuth: true, name: "nominas" },
    component: Nominas,
  },
  {
    path: "nomina_editar/:id",
    name: "nomina_editar",
    meta: { requiresAuth: true, name: "nomina_editar" },
    component: Nomina_Editar,
  },
  {
    path: "nomina_editar/:liquidacionId/ajute_persona/:liquidacionPersonaId",
    name: "NominaAjustePersona",
    meta: { requiresAuth: true, name: "NominaAjustePersona" },
    component: () =>
      import("@/views/Helexium/Nomina/NominaAjustePersona/NominaAjustePersona"),
    props: (route) => ({
      liquidacionId: String(route.params.liquidacionId),
      liquidacionPersonaId: String(route.params.liquidacionPersonaId),
    }),
  },
  {
    path: "Preparar/:liquidacionId",
    name: "Preparar",
    meta: { requiresAuth: true, name: "Preparar" },
    component: () => import("@/views/Helexium/Nomina/Preparar/NominaPreaparar"),
    props: (route) => ({ liquidacionId: String(route.params.liquidacionId) }),
  },
  {
    path: "RegistroNovedades/:Id",
    name: "RegistroNovedades",
    meta: { requiresAuth: true, name: "RegistroNovedades", sinLoader: true },
    component: RegistroNovedades,
  },
  {
    path: "liquidacion",
    name: "liquidacion",
    meta: { requiresAuth: true, name: "liquidacion" },
    component: Liquidacion,
  },
  {
    path: "liquidacion_agregar",
    name: "liquidacion_agregar",
    meta: { requiresAuth: true, name: "liquidacion_agregar" },
    component: Liquidacion_Agregar,
  },
  {
    path: "liquidacion_editar/:id",
    name: "liquidacion_editar",
    meta: { requiresAuth: true, name: "liquidacion_editar" },
    component: Liquidacion_Editar,
  },
  {
    path: "liquidacion_editarv2/:id",
    name: "liquidacion_editarv2",
    meta: { requiresAuth: true, name: "liquidacion_editarv2" },
    component: LiquidacionEditarPrimaServicios,
  },
  {
    path: "liquidacioneditardetalle/:id/:user",
    name: "liquidacioneditardetalle",
    meta: { requiresAuth: true, name: "liquidacioneditardetalle" },
    component: liquidacioneditardetalle,
  },
  {
    path: "liquidacioneditardetallev2/:id/:user",
    name: "liquidacioneditardetallev2",
    meta: { requiresAuth: true, name: "liquidacioneditardetallev2" },
    component: liquidacioneditardetallev2,
  },
  {
    path: "AutorizacionFunciones",
    name: "AutorizacionFunciones",
    meta: { requiresAuth: true, name: "AutorizacionFunciones" },
    component: AutorizacionFunciones,
  },
  {
    path: "TerminosYCondiciones",
    name: "TerminosYCondiciones",
    meta: { requiresAuth: true, name: "TerminosYCondiciones" },
    component: TerminosYCondiciones,
  },
  // {
  //   path: ':area/FichaIndicadores',
  //   name: 'FichaIndicadores',
  //   meta: { requiresAuth:true,  name: 'Data Table ' },
  //   component: FichaIndicadores
  // },
  // {
  //   path: 'caracterizacionEnfermedadLaboral',
  //   name: 'helexium.CaracterizacionEnfermedadLaboral',
  //   meta: { requiresAuth:true,  name: 'Data Table ' },
  //   component: CaracterizacionEnfermedadLaboral
  // },
  {
    path: "nomina",
    name: "Nomina",
    component: () =>
      import("@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina"),
    redirect: { name: "NominaDashboard" },
    children: [
      {
        path: "dashboard",
        name: "NominaDashboard",
        component: () =>
          import(
            "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/NominaDashboard"
          ),
      },
      {
        path: "proceso",
        name: "NominaProceso",
        component: () =>
          import(
            "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/NominaProceso"
          ),
        redirect: { name: "Periodo" },
        children: [
          {
            path: "personas_novedades",
            name: "PersonasNovedades",
            component: () => import("@/views/Helexium/Persona/Persona"),
          },
          {
            path: "persona_novedades/:Id",
            name: "PersonaNovedades",
            component: () =>
              import(
                "@/views/Helexium/Nomina/RegistroNovedades/RegistroNovedades"
              ),
          },
          {
            path: "periodo",
            name: "Periodo",
            component: () =>
              import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Agregar"),
          },
          {
            path: "preparar/:liquidacionId",
            name: "NominaPreparar",
            component: () =>
              import("@/views/Helexium/Nomina/Preparar/NominaPreaparar"),
            props: (route) => ({
              liquidacionId: String(route.params.liquidacionId),
            }),
          },
          {
            path: "transmitir/:liquidacionId",
            name: "NominaTransmitir",
            component: () =>
              import(
                "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/Proceso/NominaTransmitir"
              ),
            props: (route) => ({
              liquidacionId: String(route.params.liquidacionId),
            }),
          },
        ],
      },
    ],
  },
  {
    path: "LegoSistemaDeGestion",
    name: "Lego",
    component: () => import("@/views/Helexium/Lego/LegoLayout"),
    redirect: { name: "TusTableros" },
    children: [
      {
        path: "TusTableros",
        name: "TusTableros",
        meta: { sinLoader: true },
        component: () => import("@/views/Helexium/Lego/LegoViewTusTableros"),
        children: [
          {
            path: "CrearTablero",
            name: "CrearTablero",
            meta: { sinLoader: true },
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalCrearTablero"),
          },
        ],
      },
      {
        path: "LegoAdministradorHelexium",
        name: "LegoAdministradorHelexium",
        component: () =>
          import("@/views/Helexium/Lego/LegoViewAdministradorHelexium"),
      },
      {
        path: "Tablero/:boardId",
        name: "Tablero",
        meta: { sinLoader: true },
        component: () => import("@/views/Helexium/Lego/LegoViewTablero"),
        props: (route) => ({ boardId: String(route.params.boardId) }),
        children: [
          {
            path: "Colaboradores",
            name: "TableroColaboradores",
            meta: { sinLoader: true },
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalColaboradores"),
            props: (route) => ({ ...route.params }),
          },
          {
            path: "Tarjeta/:cardId",
            name: "LegoTarjeta",
            meta: { sinLoader: true },
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalTarjeta"),
            props: (route) => ({ ...route.params, card: route.params.cardId }),
          },
          {
            path: "NuevaPlantilla",
            name: "ToDoTemplate",
            meta: { sinLoader: true },
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalToDoTemplate"),
            props: (route) => ({ ...route.params }),
          },
          {
            path: ":process/:cardId",
            name: "LegoCardProcess",
            meta: { sinLoader: true },
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalProcess"),
            props: (route) => ({ ...route.params, card: route.params.cardId }),
          },
        ],
      },
    ],
  },
  {
    path: "assistant",
    name: "assistantView",
    component: () => import("@/views/Helexium/Assistant/AssistantView"),
    redirect: { name: "ChatResultadosEvaluacion" },
    children: [
      {
        path: "ResultadosEvaluacion",
        name: "ChatResultadosEvaluacion",
        meta: { sinLoader: true },
        component: () =>
          import("@/views/Helexium/Assistant/ChatResultadosEvaluacion"),
      },
    ],
  },

  {
    // Modulo para verificar conexion a la api de php
    path: "ConexionDian",
    name: "ConexionDian",
    component: () => import("@/views/Helexium/ConexionDian/ConexionDian"),
  },

  {
    path: "LiquidadorNomina/:liquidacionId",
    name: "LiquidadorNomina",
    component: () =>
      import("@/views/Helexium/Nomina/LiquidadorNomina/LiquidadorNomina"),
    props: (route) => ({ liquidacionId: String(route.params.liquidacionId) }),
  },
];

const routes = [
  {
    path: "/dashboard",
    name: "dashboard_nuevo",
    meta: { requiresAuth: true, name: "dashboard_nuevo", public: true },
    component: dashboard_nuevo,
  },
  {
    path: "/",
    name: "auth.login",
    meta: { name: "SignIn" },
    component: SignIn,
  },

  {
    path: "/helex",
    name: "helex",
    component: () => import("@/layouts/backend/Layout-9"),
    children: helexiumRoutes(),
  },

  {
    path: "*",
    name: "_404",
    component: NotFound,
  },
  ...publicViews,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

function getAccessMap() {
  try {
    const token = localStorage.getItem("tk");
    if (token) {
      const decodedToken = jwtDecode(token);

      // Imprimir el contenido decodificado del token
      console.log("Contenido decodificado del token:", decodedToken);

      // Obtener el mapa de acceso de la persona
      let personaMapaAccesos = decodedToken.MapaAccesos
        ? JSON.parse(decodedToken.MapaAccesos)
        : null;

      // Si el mapa de la persona es null, usar el mapa de accesos del rol
      if (!personaMapaAccesos) {
        personaMapaAccesos = decodedToken.MapaAccesosRol
          ? JSON.parse(decodedToken.MapaAccesosRol)
          : null;
      }

      return personaMapaAccesos;
    }
  } catch (error) {
    console.error("Error al decodificar el token:", error);
  }
  return null;
}

function hasAccess(currentRoute, accessMap) {
  // Función auxiliar para comparar rutas con expresiones regulares
  function matchRoutes(routeA, routeB) {
    if (typeof routeA !== "string" || typeof routeB !== "string") {
      console.error("Error en matchRoutes: Las rutas deben ser strings");
      return false;
    }

    try {
      // Convertir las rutas a expresiones regulares
      const regex = new RegExp(
        "^" +
          routeA
            .replace(/\/:\w+\?/g, "(?:/[^/]+)?") // Detecta parámetros opcionales
            .replace(/:\w+/g, "[^/]+") + // Detecta parámetros normales
          "$",
        "i"
      );
      return regex.test(routeB);
    } catch (error) {
      console.error("Error al comparar rutas:", error);
      return false;
    }
  }

  // Obtener la ruta actual
  const currentPath = currentRoute.path;

  // Buscar la ruta en el mapa de accesos
  for (const route in accessMap) {
    if (matchRoutes(route, currentPath)) {
      // Si la ruta existe en el mapa, devolver el valor de acceso
      return accessMap[route] === 1;
    }
  }

  // Si la ruta no se encuentra en el mapa, asumir que el acceso está denegado
  return false;
}

// vistas publicas sin autenticación
const publicRoutes = [
  "auth.login",
  "Reclutamiento",
  "ListaDeVacantes",
  "InformacionVacante",
  "FormularioDePostulacion",
  "FormularioSubDePostulacion",
  "CargarDocumentosDeSeleccion",
  "NavegacionAspirante",
];

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("tk");
  const accessMap = getAccessMap();

  const isPublicRoute = publicRoutes.includes(to.name);

  if (isPublicRoute) {
    return next();
  }

  // Redirección si no está autenticado
  if (!isAuthenticated) {
    return next({ name: "auth.login" });
  }

  // Manejo de rutas que requieren autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (hasAccess(to, accessMap) || to.meta.public) {
      //Para las vistas públicas debe hacer una sesion
      userLoggedIn(); // Movido aquí para ejecutarse solo cuando el acceso es concedido
      return next();
    } else {
      return next({ name: "NoAccess" });
    }
  }

  // Para todas las demás rutas
  userLoggedIn();
  next();
});

export default router;
